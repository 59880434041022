/** npm import */
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

/** local file import */
import classes from '../asset/css/Thank.module.css'
import HeaderOne from '../Layout/HeaderOne'
import FooterAddress from '../Layout/FooterAddress'

/** code start */

const ThankYou = () => {

    const navigate = useNavigate()
    return (
        <>
            <main>
                <HeaderOne />
                <section className={`${classes.thankyou}`}>
                    <div className={`container ${classes.container}`}>
                        <div className="row justify-content-center">
                            <div className="col-11  col-xxl-8">
                                <div className={`${classes.card}`}>
                                    <h5>Thank You for Your Interest in ISCC Certification!</h5>
                                    <p className='mt-4'>Dear [User's Name],
                                    </p>
                                    <p className='mt-3'>
                                        Thank you for expressing interest in obtaining the ISCC certification through QSI Wand. We are thrilled to support you in your journey towards sustainability and excellence.
                                    </p>
                                    <p className='mt-3'>
                                        The ISCC certification is a globally recognized standard for sustainable supply chains, ensuring that your products meet high environmental and social responsibility criteria.
                                        Our platform will guide you through each step of the certification process, making it as smooth
                                        and efficient as possible.
                                    </p>
                                    <div className={`d-flex align-items-center justify-content-center mt-4`}>
                                        <button className={`${classes.detail_button}`} onClick={() => navigate('/dashboard')}>
                                            Detailed Application
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <FooterAddress />
            </main >
        </>
    )
}

export default ThankYou