/** npm import */
import React from 'react'
import { Link } from 'react-router-dom'

/** local file import */
import classes from '../asset/css/FooterAddress.module.css'
import { Images } from '../data/images'

/** code start */
const FooterAddress = () => {
    return (
        <div className={`${classes.footer} d-flex flex-wrap align-items-center justify-content-between gap-4`}>
            <div className={`${classes.left}`}>
                <p className={`${classes.p_black}`}>PT Qualitas Sertifikasi Indonesia</p>
                <p className={`${classes.p_blue}`}>THE CEO, Jl. TB Simatupang No.18C Building Level 12th, Cilandak Bar., Kec. Cilandak,</p>
                <p className={`${classes.p_blue}`}>Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12430, Indonesia</p>
                <p className={`${classes.p_black}`}>Email: admin@qualitasertifikasi.com</p>
            </div>
            <div className={`${classes.right} d-flex align-items-center gap-3`}>
                <a href='#'>
                    <img src={Images.facebook} className='img-fluid' />
                </a>
                <a href='#'>
                    <img src={Images.twitter} className='img-fluid' />
                </a>
                <a href='#'>
                    <img src={Images.linkedin} className='img-fluid' />
                </a>
            </div>
        </div>
    )
}

export default FooterAddress