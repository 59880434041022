/** npm import */
import React from 'react'
import { Link } from 'react-router-dom'

/** local file import */
import classes from '../asset/css/Header.module.css'
import { Images } from '../data/images'

/** code start */

const HeaderOne = () => {
    return (
        <header className={`${classes.header}`}>
            <div className={`${classes.logo}`}>
                <img src={Images.gear} className='img-fluid' />
                <p className={`${classes.name}`}>QSI Wand</p>
            </div>
        </header>
    )
}

export default HeaderOne    