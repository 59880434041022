import { createContext, useEffect, useState } from "react";
import useWindowResize from "../hooks/useWindowResize";

export const GlobalContext = createContext(null);

const GlobalState = ({ children }) => {
    const windowSize = useWindowResize();
    const { width } = windowSize;
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [Display, SetDisplay] = useState(false);

    // function
    const handleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        if (width > 0) {
            SetDisplay(true);
        }
        if (width >= 1200 && !isSidebarOpen) {
            setIsSidebarOpen(true);
        } else if (width < 1200 && isSidebarOpen) {
            setIsSidebarOpen(false);
        }
    }, [width]);

    return (
        <>
            {Display ? (
                <GlobalContext.Provider
                    value={{
                        isSidebarOpen,
                        setIsSidebarOpen,
                        handleSidebar,
                    }}
                >
                    {children}
                </GlobalContext.Provider>
            ) : (
                <></>
            )}
        </>
    );
};

export default GlobalState;
