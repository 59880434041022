// import package

import React, { useContext, useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoClose } from "react-icons/io5";
import Offcanvas from "react-bootstrap/Offcanvas";

// import local

import { GlobalContext } from "../context/Context";
import { Images } from "../data/images";
// import { Image } from "../../data/Images";

const Header = () => {
    const { isSidebarOpen } = useContext(GlobalContext);

    const [offCanvaOpen, setOffCanvaOpen] = useState(false);

    const handleOffCanvaOpen = () => {
        setOffCanvaOpen(true);
    };
    const handleOffCanvaClose = () => {
        setOffCanvaOpen(false);
    };
    return (
        <>
            <div
                className={`${isSidebarOpen ? "width_less" : "width_full"} dash_header`}
            >
                <div className="container container_95 h-100">
                    <div className="d-flex align-items-center justify-content-end gap-3 h-100">
                        <div className="header-search">
                            <input placeholder="Search" />
                        </div>
                        <button className="header-icon__button d-none d-md-block">
                            <img src={Images.search} className="img-fluid" />
                        </button>
                        <button className="header-icon__button d-none d-md-block">
                            <img src={Images.query} className="img-fluid" />
                        </button>
                        <button className="header-icon__button d-none d-md-block">
                            <img src={Images.notify} className="img-fluid" />
                        </button>

                        <button className="header-icon__button d-none d-md-block">
                            <img src={Images.settingsblack} className="img-fluid" />
                        </button>

                        <button className="header-profile__wrapper">
                            <img src={Images.profileIcon} />
                        </button>
                    </div>
                </div>
            </div>

            <Offcanvas
                show={offCanvaOpen}
                onHide={handleOffCanvaClose}
                backdrop="static"
                placement="top"
                className="custom_canva "
            >
                <Offcanvas.Header closeButton>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        {/* <img
                            src={Image.logo}
                            alt="logo"
                            className="img-fluid sidebar_logo"
                        /> */}
                        <button
                            className="border-0 outline-0 bg-transparent"
                            onClick={handleOffCanvaClose}
                        >
                            <IoClose color="#fff" fontSize={25} />
                        </button>
                    </div>{" "}
                </Offcanvas.Header>
                <Offcanvas.Body className="d-flex align-items-center">
                    <div className="d-flex flex-wrap align-items-center gap-3">
                        {" "}
                        <div className="dashHeader__searchHolder d-flex justify-content-between align-items-center gap-2  rounded-4 px-3 py-1">
                            <div className="row mx-auto">
                                <div className="col-10 px-0">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className=" flex-grow-1 "
                                    />
                                </div>
                                <div className="col-2 pe-0 d-flex justify-content-end align-items-center">
                                    {/* <img
                                        src={Image.searchGlass}
                                        className="img-fluid dashHeader__btnImages"
                                    /> */}
                                </div>
                            </div>
                        </div>
                        {/* <button className="gray_circle_btn ">
                            <img src={Image.notify} className="img-fluid " />
                        </button>
                        <button className="gray_circle_btn ">
                            <img src={Image.mail} className="img-fluid " />
                        </button>
                        <button className="gray_circle_btn">
                            <img src={Image.settings} className="img-fluid" />
                        </button> */}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default Header;
