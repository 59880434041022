export const Images = {
    logo: require('../asset/images/logo.png'),
    image: require('../asset/images/image.png'),
    logoIcon: require('../asset/images/Frame1.png'),
    singupCover: require('../asset/images/sign-up-cover.png'),
    lock: require('../asset/images/lock.png'),
    unlock: require('../asset/images/unlock.png'),
    uploadIcon: require('../asset/images/upload_icon.png'),
    download: require('../asset/images/download.png'),
    print: require('../asset/images/print.png'),
    formUpload: require('../asset/images/formupload.png'),
    previous: require('../asset/images/previous.png'),
    previousSkip: require('../asset/images/previousskip.png'),
    next: require('../asset/images/next.png'),
    nextSkip: require('../asset/images/nextskip.png'),
    dashboard: require('../asset/images/dash_icon.png'),
    dashlogo: require('../asset/images/dashlogo.png'),
    customer: require('../asset/images/customer.png'),
    project: require('../asset/images/project.png'),
    task: require('../asset/images/task.png'),
    audit: require('../asset/images/audit.png'),
    reminders: require('../asset/images/reminders.png'),
    plan: require('../asset/images/plan.png'),
    auditbg: require('../asset/images/auditbg.png'),
    postaudit: require('../asset/images/postaudit.png'),
    settings: require('../asset/images/settings.png'),
    logout: require('../asset/images/logout.png'),
    chat: require('../asset/images/chat.png'),
    notification: require('../asset/images/notification.png'),
    settingsblack: require('../asset/images/settingsblack.png'),
    search: require('../asset/images/search.png'),
    query: require('../asset/images/query.png'),
    issue: require('../asset/images/issue.png'),
    pending: require('../asset/images/pending.png'),
    expire: require('../asset/images/expire.png'),
    alert: require('../asset/images/alert.png'),
    docx: require('../asset/images/docx.png'),
    jpg: require('../asset/images/jpg.png'),
    ppt: require('../asset/images/ppt.png'),
    base: require('../asset/images/base.png'),
    tiger: require('../asset/images/tiger.png'),
    desc: require('../asset/images/desc.png'),
    bell: require('../asset/images/bell.png'),
    edit: require('../asset/images/edit.png'),
    paper: require('../asset/images/paper.png'),
    reminder: require('../asset/images/reminder.png'),
    study: require('../asset/images/study.png'),
    printer: require('../asset/images/printer.png'),
    notify: require('../asset/images/notify.png'),
    checked: require('../asset/images/checked.png'),
    facebook: require('../asset/images/facebook.png'),
    linkedin: require('../asset/images/linkedin.png'),
    twitter: require('../asset/images/twitter.png'),
    gear: require('../asset/images/gear.png'),
    extend: require('../asset/images/extend.png'),
    eye: require('../asset/images/eye.png'),
    profileIcon: require('../asset/images/profileicon.png'),

}