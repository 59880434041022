/** npm import */
import React, { useState } from 'react'

/** local file import */
import classes from '../asset/css/Signin.module.css'
import { Images } from '../data/images'
import Footer from '../components/Footer';
import { Link, useNavigate } from 'react-router-dom';


/** code start */

const initialFormData = {
    emailId: '',
    password: '',
}


const Signin = () => {

    const navigate = useNavigate()


    const [passwordType, setPasswordType] = useState('password');
    const [confirmPasswordType, setConfirmPasswordType] = useState('password');


    const handlePasswordType = () => {
        setPasswordType(passwordType === 'password' ? "text" : "password")
    }
    const handleConfirmPasswordType = () => {
        setConfirmPasswordType(confirmPasswordType === 'password' ? "text" : "password")
    }
    const handleFormSubmit = (e) => {
        e.preventDefault()
    }

    const handleSignin = () => {
        navigate('/dashboard')
    }



    return (
        <>
            <main className={`${classes.applyCertification}`}>
                <section className={`${classes.section} mb-4 mb-md-5`}>
                    <div className={`container ${classes.container}`}>
                        <div className={`${classes.header}`}>
                            <div className="row d-flex align-items-center">
                                <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-start">
                                    <div className={`${classes.logo}`}>
                                        <img src={Images.logo} alt='logo' className='img-fluid' />
                                    </div>
                                </div>
                                {/* <div className="col-12 col-md-6 mt-5 mt-md-0">
                                    <p className={`${classes.title}`}>Sign Up</p>
                                </div> */}
                            </div>
                        </div>
                        <div className={`${classes.body} `}>
                            <div className='row'>
                                <div className="col-12 col-lg-6 d-flex justify-content-center justify-content-md-start">
                                    <div className={`${classes.left} w-100`}>
                                        <div className={`${classes.card}`}>
                                            <p className={`${classes.heading}`}>
                                                Explore QSI Wand Magic
                                            </p>
                                            <p className={`${classes.subHeading} mt-1`}>
                                                Discover tailored audit solutions for every stage!
                                            </p>
                                            <img
                                                className={`${classes.image}  mt-2`}
                                                src={Images.singupCover} />
                                            <p className={`${classes.footer} mt-3`}>For the Glory Seeker. A powerful and immensely loyal wand, so long
                                                as its wielder doesn’t turn lazy, in which case it will be won away</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-9 col-sm-8 col-md-6  col-lg-5 col-xl-4 mx-auto  mt-5 mt-lg-3">
                                    <div className={`${classes.right}`}>
                                        <p className={`${classes.heading}`}>Sign In with QSI Wand</p>
                                        <p className={`${classes.subHeading} mt-1`}>Experience seamless certification journey with us</p>
                                        <form className={`${classes.forms} mt-4 mt-lg-5`} onSubmit={handleFormSubmit}>

                                            <div className={`${classes.form} mt-3`}>
                                                <input
                                                    className={`${classes.input} mt-2`}
                                                    placeholder='Business email'

                                                    id='emailId'

                                                />
                                                {/* {
                                                    validateErrors?.emailId
                                                        ?
                                                        <p className={`${classes.error} mt-1`}>{validateErrors?.emailId}</p>
                                                        :
                                                        <></>
                                                } */}

                                            </div>
                                            <div className={`${classes.form} mt-3`}>
                                                <div className={`${classes.password_container}  mt-2`}>
                                                    <input
                                                        type={passwordType}
                                                        placeholder='Password'
                                                        id='password'
                                                    />
                                                    <button onClick={handlePasswordType}>
                                                        <img src={passwordType === 'password' ? Images.lock : Images.unlock}
                                                            className='img-fluid' />
                                                    </button>
                                                </div>
                                                {/* {
                                                    validateErrors?.password
                                                        ?
                                                        <p className={`${classes.error} mt-1`}>{validateErrors?.password}</p>
                                                        :
                                                        <></>
                                                } */}
                                            </div>

                                            <button type='button' className={`${classes.button} mt-4`} onClick={handleSignin} >Sign In</button>
                                            <div className={`${classes.links} mt-2`}>
                                                <Link to='/forgotpassword' >Forgot Password</Link>
                                                <Link to='/' >Sign Up</Link>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </main>
        </>
    )
}

export default Signin