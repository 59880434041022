

/** npm import */

import React, { useState } from 'react'
import { IoShareSocialSharp } from "react-icons/io5";
import Select from 'react-select';
import { FaArrowDown, FaArrowRight } from "react-icons/fa6";
import ReactApexChart from "react-apexcharts"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaChevronDown } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";



/** local file import */

import Layout from '../components/Layout'
import classes from '../asset/css/Dashboard.module.css'


/** code start */

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
];

const programOptions = [
    { value: 'program1', label: 'Program 1' },
    { value: 'program2', label: 'Program 2' },
    { value: 'program3', label: 'Program 3' },
    { value: 'program4', label: 'Program 4' },
]
const monthOptions = [
    { value: 'January', label: 'January ' },
    { value: 'February', label: 'February' },
    { value: 'March', label: 'March' },
    { value: 'April', label: 'April' },
    { value: 'May', label: 'May' },
    { value: 'June', label: 'June' },
    { value: 'July', label: 'July' },
    { value: 'August', label: 'August' },
    { value: 'September', label: 'September ' },
    { value: 'October', label: 'October' },
    { value: 'November', label: 'November' },
    { value: 'December', label: 'December' },

]

const stageGateGraph = {

    series: [
        {
            data: [50, 10, 85, 56, 73, 58, 95]
        }
    ],
    options: {
        chart: {
            type: 'bar',
            height: 350,
            // stacked: true,
            // stackType: '100%'
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '13px',
                endingShape: 'rounded',
                borderRadius: 6,
                borderRadiusApplication: 'end',

                // borderRadiusWhenStacked: 'last',
            },
        },
        dataLabels: {
            enabled: false
        },
        colors: ["#000"],
        // stroke: {
        //     show: true,
        //     width: 2,
        //     colors: ['transparent']
        // },
        xaxis: {
            categories: ['Apply', 'Contract', 'Payment', 'Plan', 'Onsite', 'Findings', 'Issued'],
        },
        yaxis: {
            title: {
                text: 'Percentage'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + " %"
                }
            }
        }
    },


};
const revenueGraph = {

    series: [
        {
            data: [4000, 3000, 3500, 1100, 1500, 3800, 600, 1800, 2100, 3500, 550]
        }
    ],
    options: {
        chart: {
            type: 'bar',
            height: 350,
            // stacked: true,
            // stackType: '100%'
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '8px',
                endingShape: 'rounded',
                borderRadius: 4,
                // borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'last',
            },
        },
        dataLabels: {
            enabled: false
        },
        colors: ["#000"],
        // stroke: {
        //     show: true,
        //     width: 2,
        //     colors: ['transparent']
        // },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yaxis: {
            title: {
                text: 'Revenue'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return " $" + val
                }
            }
        }
    },


};

const Dashboard = () => {

    const [startDate, setStartDate] = useState(new Date());
    return (
        <Layout>
            <section>
                <p className={classes.pageTitle}>
                    Key Performance indicators
                </p>
                <div className='row mt-4 mt-sm-5 ' >
                    <div className='col-12 col-lg-6'>
                        <div className={classes.dashLeft}>
                            <div className='row '>
                                <div className='col-12 col-sm-6'>
                                    <div className={classes.topCard}>
                                        <div className={`${classes.details} d-flex justify-content-between`}>
                                            <div>
                                                <p className={`${classes.title} `}>Total Audit Projects</p>
                                                <p className={`${classes.value} mt-2`}>182</p>
                                            </div>
                                            <button className={`${classes.imageWrapper}`}>
                                                <IoShareSocialSharp />
                                            </button>
                                        </div>
                                        <button className={`${classes.button} mt-3`}> New Project</button>
                                    </div>
                                </div>
                                <div className='col-12 col-sm-6 mt-3 mt-sm-0'>
                                    <div className={classes.topCard}>
                                        <div className={`${classes.details} d-flex justify-content-between`}>
                                            <div>
                                                <p className={`${classes.title} `}>Team Size</p>
                                                <p className={`${classes.value} mt-2`}>22</p>
                                            </div>
                                            <button className={`${classes.imageWrapper}`}>
                                                <FaUsers />
                                            </button>
                                        </div>
                                        <button className={`${classes.button} mt-3`}> Add Members</button>
                                    </div>
                                </div>
                            </div>

                            {/* project pipeline */}
                            <div className={`${classes.projectPipeline} mt-4`}>
                                <div className={`${classes.header} d-flex flex-wrap align-items-center gap-3 justify-content-between`}>
                                    <p className={`${classes.title}`}>Project Pipeline</p>
                                    <div className={`${classes.filters} d-flex flex-wrap align-items-center gap-2`}>
                                        <div className=''>
                                            <Select
                                                options={monthOptions}
                                                classNamePrefix="select-three"
                                                placeholder="Month"
                                                isSearchable={false}
                                            // menuIsOpen={true}
                                            />
                                        </div>
                                        <div className='datepicker-one'>
                                            <DatePicker
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                showYearPicker
                                                dateFormat="yyyy"
                                            />
                                            <FaChevronDown />

                                        </div>
                                    </div>

                                </div>
                                <div className={`${classes.table} d-flex mt-3`}>
                                    <div className={`${classes.program} ${classes.tableWidth} `}>
                                        <ul>
                                            <li>Program</li>
                                            <li>ISCC</li>
                                            <li>TEXTILE EXCHANGE</li>
                                            <li>SVLK</li>
                                            <li>GHG</li>
                                        </ul>

                                    </div>
                                    <div className={`${classes.application} ${classes.tableWidth}`}>
                                        <ul>
                                            <li>Application</li>
                                            <li>2312</li>
                                            <li>54</li>
                                            <li>454</li>
                                            <li>9889</li>
                                        </ul>

                                    </div>
                                    <div className={`${classes.pass} ${classes.tableWidth}`}>
                                        <ul>
                                            <li>Pass</li>
                                            <li>98787</li>
                                            <li>34524</li>
                                            <li>04567</li>
                                            <li>2345</li>
                                        </ul>

                                    </div>
                                    <div className={`${classes.fail} ${classes.tableWidth}`}>
                                        <ul>
                                            <li>Fail</li>
                                            <li>96763</li>
                                            <li>9787</li>
                                            <li>2134</li>
                                            <li>6545</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* kpi */}

                            <div className={`${classes.kpi} mt-4`}>
                                <div className={`${classes.header} d-flex align-items-center gap-3 justify-content-between`}>
                                    <p className={`${classes.title}`}>Other KPIs</p>
                                    <button>View All  <FaArrowRight /></button>
                                </div>
                                <div className='row mt-4'>
                                    <div className='col-6 col-sm-3'>
                                        <div className={`${classes.kpiCard}`}>
                                            <p className={`${classes.textLg}`}>35D</p>
                                            <p className={`${classes.textSm}`}>Turnaround</p>
                                        </div>
                                    </div>
                                    <div className='col-6 col-sm-3'>
                                        <div className={`${classes.kpiCard}`}>
                                            <p className={`${classes.textLg}`}>248</p>
                                            <p className={`${classes.textSm}`}>Reports</p>
                                        </div>
                                    </div>
                                    <div className='col-6 col-sm-3 mt-3 mt-sm-0'>
                                        <div className={`${classes.kpiCard}`}>
                                            <p className={`${classes.textLg}`}>4.2</p>
                                            <p className={`${classes.textSm}`}>Process</p>
                                        </div>
                                    </div>

                                    <div className='col-6 col-sm-3 mt-3 mt-sm-0'>
                                        <div className={`${classes.kpiCard}`}>
                                            <p className={`${classes.textLg}`}>4.2</p>
                                            <p className={`${classes.textSm}`}>Satisfaction</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6 mt-3 mt-lg-0'>
                        <div className={`${classes.dashRight}`}>
                            <div className={`${classes.graphInner} ${classes.stageGraph} d-flex flex-column justify-content-between gap-3`}>
                                <div>
                                    <div className={`${classes.header} d-flex flex-wrap align-items-center justify-content-between gap-3 `}>
                                        <p className={`${classes.title}`}>Stages</p>
                                        <div className='d-flex flex-wrap align-items-center gap-2'>
                                            <div className=''>
                                                <Select
                                                    options={programOptions}
                                                    classNamePrefix="select-three"
                                                    placeholder="Program"
                                                    isSearchable={false}
                                                // menuIsOpen={true}
                                                />
                                            </div>

                                            <div className=''>
                                                <div className=''>
                                                    <Select
                                                        options={monthOptions}
                                                        classNamePrefix="select-three"
                                                        placeholder="Month"
                                                        isSearchable={false}
                                                    // menuIsOpen={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='datepicker-one'>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    showYearPicker
                                                    dateFormat="yyyy"
                                                />
                                                <FaChevronDown />

                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${classes.headerData} d-flex flex-wrap align-items-center gap-3 justify-content-between mt-3`}>
                                        <p className={`${classes.subTitle}`}>Overall 90%</p>
                                        <div className={`${classes.batch}`}>
                                            Avg.148 days / program
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div id="chart">
                                        <ReactApexChart options={stageGateGraph.options} series={stageGateGraph.series} type="bar" height={200} />
                                    </div>
                                </div>
                            </div>
                            <div className={`${classes.graphInner} ${classes.revenueGraph} d-flex flex-column justify-content-between gap-3`}>
                                <div>
                                    <div className={`${classes.header} d-flex flex-wrap align-items-center justify-content-between gap-3 `}>
                                        <p className={`${classes.title}`}>Stages</p>
                                        <div className='d-flex flex-wrap align-items-center gap-2'>
                                            <div className=''>
                                                <div className=''>
                                                    <Select
                                                        options={monthOptions}
                                                        classNamePrefix="select-three"
                                                        placeholder="Month"
                                                        isSearchable={false}
                                                    // menuIsOpen={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='datepicker-one'>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                    showYearPicker
                                                    dateFormat="yyyy"
                                                />
                                                <FaChevronDown />

                                            </div>
                                        </div>
                                    </div>
                                    <div className={`${classes.headerData} d-flex flex-wrap align-items-center gap-3 mt-3`}>
                                        <p className={`${classes.subTitle}`}>
                                            + $12,856.14
                                        </p>
                                        <div className={`${classes.batch}`}>
                                            Avg.$3000/month
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div id="chart">
                                        <ReactApexChart options={revenueGraph.options} series={revenueGraph.series} type="bar" height={200} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Dashboard