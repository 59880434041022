
/** npm import */
import React from 'react'
import { Link } from 'react-router-dom'

/** local file import */
import classes from '../asset/css/Footer.module.css'

/** code start */

const Footer = () => {
    return (
        <footer className={`${classes.footer}`}>
            <ul className={`${classes.ul}`}>
                <li>
                    <Link to='/' className={`${classes.link}`}>Privacy Policy</Link>
                </li>
                <li>
                    <Link to='/' className={`${classes.link}`}>Terms of Service</Link>
                </li>
                <li>
                    <Link to='/' className={`${classes.link}`}>Contact Us</Link>
                </li>
            </ul>
        </footer>
    )
}

export default Footer