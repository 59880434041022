/** npm import */
import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select';


/** local file import */
import classes from '../asset/css/Projects.module.css'

import { Images } from '../data/images'
import { useLocation, useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import ApplicationReview from '../modal/ApplicationReview';
import PrepareContract from '../modal/PrepareContract';

/** code start */

const paginationOptions = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
]


const Projects = () => {

    // const userAxios = userAxiosFile();
    const navigate = useNavigate();


    const [projectList, setProjectList] = useState([]);

    const [reviewOpen, setReviewOpen] = useState(false)
    const [contractOpen, setContractOpen] = useState(false)

    const handleShowReview = () => {
        setReviewOpen(true)
    }

    const handleCloseReview = () => {
        setReviewOpen(false)
    }

    const handleShowContract = () => {
        setContractOpen(true)
    }

    const handleCloseContract = () => {
        setContractOpen(false)
    }

    return (
        <Layout>
            <ApplicationReview show={reviewOpen} handleClose={handleCloseReview} />
            <PrepareContract show={contractOpen} handleClose={handleCloseContract} />
            <section>

                <p className={classes.pageTitle}>
                    Project details
                </p>
                <div className={`${classes.project_table} mt-4`}>
                    <p className={`${classes.section_title}`}>
                        Projects Details
                    </p>
                    <div className="static-table-3 mt-4">
                        <div style={{ overflowX: "scroll" }}>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th className='table-wid__55'>
                                            <div className={`${classes.check_container}   check_container table_checkbox`}>
                                                <label className={`${classes.check_margintop} checkbox_container`}>
                                                    <input type="checkbox" />
                                                    <span class="checkbox_checkmark"></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th className='table-wid__230'>
                                            <p className='table-head__bold'>Description</p>
                                        </th>
                                        <th className='table-wid__150'>
                                            <p className='table-head__bold'>Programme</p>
                                        </th>
                                        <th className='table-wid__150'>
                                            <p className='table-head__bold'>Scope</p>
                                        </th>
                                        <th className='table-wid__180'>
                                            <p className='table-head__bold'>Input-output</p>
                                        </th>
                                        <th className='table-wid__150'>
                                            <p className='table-head__bold'>PIC</p>
                                        </th>
                                        <th className='table-wid__150'>
                                            <p className='table-head__bold'>TIPE</p>
                                        </th>
                                        <th className='table-wid__230'>
                                            <p className='table-head__bold'>Risk Assess (Pre)</p>
                                        </th>
                                        <th className='table-wid__150'>
                                            <p className='table-head__bold'>Auditor</p>
                                        </th>
                                        <th className='table-wid__150'>
                                            <p className='table-head__bold'>Pre CB</p>
                                        </th>
                                        <th className='table-wid__150'>
                                            <p className='table-head__bold'>Contract</p>
                                        </th>
                                        <th className='table-wid__150'>
                                            <p className='table-head__bold'>Status</p>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {
                                        projectList?.length > 0
                                            ?
                                            (
                                                projectList?.map((val) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>
                                                                    <div className={`${classes.check_container}   check_container table_checkbox`}>
                                                                        <label className={`${classes.check_margintop} checkbox_container`}>
                                                                            <input type="checkbox" />
                                                                            <span class="checkbox_checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <p className='table-body__bold'>IS012010203</p>
                                                                </td>
                                                                <td>
                                                                    <p className='table-body__bold'>ISCC</p>
                                                                </td>
                                                                <td>
                                                                    <p className='table-body__bold'>TBA</p>
                                                                </td>
                                                                <td>
                                                                    <p className='table-body__bold'>A</p>
                                                                </td>
                                                                <td>
                                                                    <p className='table-body__bold'>B</p>
                                                                </td>
                                                                <td>
                                                                    <p className='table-body__bold'>T</p>
                                                                </td>
                                                                <td>
                                                                    <p className='table-body__bold'>info</p>
                                                                </td>
                                                                <td>
                                                                    <p className='table-body__bold'>info</p>
                                                                </td>
                                                                <td>
                                                                    <p className='table-body__bold'>info</p>
                                                                </td>
                                                                <td>
                                                                    <p className='table-body__bold'>info</p>
                                                                </td>
                                                                <td>
                                                                    <button className='button__orange'>
                                                                        Site Audit
                                                                    </button>

                                                                </td>



                                                            </tr>
                                                        </>
                                                    )
                                                })
                                            )

                                            :
                                            <tr><td colSpan={11} className='text-center'>No Projects</td></tr>
                                    } */}

                                    <tr >
                                        <td >
                                            <div className={`${classes.check_container}   check_container table_checkbox`}>
                                                <label className={`${classes.check_margintop} checkbox_container`}>
                                                    <input type="checkbox" />
                                                    <span class="checkbox_checkmark"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>IS012010203</p>
                                        </td>

                                        <td>
                                            <p className='table-body__bold'>ISCC</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>TBA</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>A</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>B</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>T</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>info</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>info</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>info</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>info</p>
                                        </td>
                                        <td>
                                            <button className='button__orange'>
                                                Site Audit
                                            </button>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${classes.check_container}   check_container table_checkbox`}>
                                                <label className={`${classes.check_margintop} checkbox_container`}>
                                                    <input type="checkbox" />
                                                    <span class="checkbox_checkmark"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>IS012010203</p>
                                        </td>

                                        <td>
                                            <p className='table-body__bold'>ISCC</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>TBA</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>A</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>B</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>T</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>info</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>info</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>info</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>info</p>
                                        </td>
                                        <td>
                                            <button className='button__orange'>
                                                Site Audit
                                            </button>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${classes.check_container}   check_container table_checkbox`}>
                                                <label className={`${classes.check_margintop} checkbox_container`}>
                                                    <input type="checkbox" />
                                                    <span class="checkbox_checkmark"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>IS012010203</p>
                                        </td>

                                        <td>
                                            <p className='table-body__bold'>ISCC</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>TBA</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>A</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>B</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>T</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>info</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>info</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>info</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>info</p>
                                        </td>
                                        <td>
                                            <button className='button__orange'>
                                                Site Audit
                                            </button>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${classes.check_container}   check_container table_checkbox`}>
                                                <label className={`${classes.check_margintop} checkbox_container`}>
                                                    <input type="checkbox" />
                                                    <span class="checkbox_checkmark"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>IS012010203</p>
                                        </td>

                                        <td>
                                            <p className='table-body__bold'>ISCC</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>TBA</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>A</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>B</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>T</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>info</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>info</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>info</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>info</p>
                                        </td>
                                        <td>
                                            <button className='button__orange'>
                                                Site Audit
                                            </button>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className={`${classes.check_container}   check_container table_checkbox`}>
                                                <label className={`${classes.check_margintop} checkbox_container`}>
                                                    <input type="checkbox" />
                                                    <span class="checkbox_checkmark"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>IS012010203</p>
                                        </td>

                                        <td>
                                            <p className='table-body__bold'>ISCC</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>TBA</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>A</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>B</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>T</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>info</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>info</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>info</p>
                                        </td>
                                        <td>
                                            <p className='table-body__bold'>info</p>
                                        </td>
                                        <td>
                                            <button className='button__orange'>
                                                Site Audit
                                            </button>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                        <div className='static-table-3__footer my-3 d-flex flex-wrap justify-content-end me-lg-5' >
                            <div className='d-flex flex-wrap align-items-center gap-4'>
                                <div className='custom-select__two mt-2'>
                                    <Select
                                        options={paginationOptions}
                                        classNamePrefix="select-two"
                                        placeholder="5 Rows"
                                        isSearchable={false}
                                    // menuIsOpen={true}
                                    />
                                </div>
                                <p className='static-table-3__pagination-details'>
                                    1 - 5 of 100
                                </p>
                                <div className='static-table-3__pagination-buttons d-flex align-items-center gap-2'>
                                    <div className='d-flex align-items-center gap-1'>
                                        <button>
                                            <img src={Images.previousSkip} />
                                        </button>
                                        <button>
                                            <img src={Images.previous} />
                                        </button>
                                    </div>
                                    <div>
                                        <button>
                                            <img src={Images.next} />
                                        </button>
                                        <button>
                                            <img src={Images.nextSkip} />
                                        </button>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className={`${classes.stage_gates} mt-4`}>
                    <p className={`${classes.section_title}`}>
                        Stage Gates
                    </p>
                    <div className={`${classes.cards} d-flex align-items-center gap-3 mt-4`}>
                        <div className="d-flex flex-wrap w-100 align-items-center gap-4 justify-content-between">
                            <div className='d-flex align-items-center gap-3'>
                                <div className={`${classes.status} ${classes.received}`}>
                                </div>
                                <div className=''>
                                    <p className={`${classes.title}`}>Application Received</p>
                                    <p className={`${classes.subTitle}`}>
                                        Completed on: 2023-01-15 | Days: 1
                                    </p>
                                </div>
                            </div>
                            <div className={`${classes.applicationActions}`}>
                                <button>
                                    <img src={Images.extend} className='img-fluid' style={{ width: "25px" }} />
                                </button>
                                <button>
                                    <img src={Images.eye} className='img-fluid' />
                                </button>
                                <button>
                                    <img src={Images.download} className='img-fluid' style={{ width: "30px" }} />
                                </button>
                            </div>

                        </div>

                    </div>
                    <div className={`${classes.cards} d-flex align-items-center gap-3 mt-4`}>
                        <div className="d-flex flex-wrap w-100 align-items-center gap-4 justify-content-between">
                            <div className='d-flex align-items-center gap-3'>
                                <div className={`${classes.status} ${classes.review}`}>
                                </div>
                                <div className=''>
                                    <p className={`${classes.title}`}>Application Review</p>
                                    <p className={`${classes.subTitle}`}>
                                        Completed on: 2023-01-15 | Days: 1
                                    </p>
                                </div>
                            </div>
                            <div className={`${classes.applicationActions}`}>
                                <button onClick={handleShowReview}>
                                    <img src={Images.extend} className='img-fluid' style={{ width: "25px" }} />
                                </button>
                                <button>
                                    <img src={Images.eye} className='img-fluid' />
                                </button>
                                <button>
                                    <img src={Images.download} className='img-fluid' style={{ width: "30px" }} />
                                </button>
                            </div>

                        </div>

                    </div>
                    <div className={`${classes.cards} d-flex align-items-center gap-3 mt-4`}>
                        <div className="d-flex flex-wrap w-100 align-items-center gap-4 justify-content-between">
                            <div className='d-flex align-items-center gap-3'>
                                <div className={`${classes.status} ${classes.review}`}>
                                </div>
                                <div className=''>
                                    <p className={`${classes.title}`}>Prepare Contract</p>
                                    <p className={`${classes.subTitle}`}>
                                        Completed on: 2023-01-15 | Days: 1
                                    </p>
                                </div>
                            </div>
                            <div className={`${classes.applicationActions}`}>
                                <button onClick={handleShowContract}>
                                    <img src={Images.extend} className='img-fluid' style={{ width: "25px" }} />
                                </button>
                                <button>
                                    <img src={Images.eye} className='img-fluid' />
                                </button>
                                <button>
                                    <img src={Images.download} className='img-fluid' style={{ width: "30px" }} />
                                </button>
                            </div>

                        </div>

                    </div>

                </div>

            </section >
        </Layout >
    )
}

export default Projects