/** npm import */
import React, { useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import { IoMdClose } from "react-icons/io";
import { FaPlus, FaMinus } from "react-icons/fa";
import { RiPencilFill } from "react-icons/ri";
import { IoClose } from "react-icons/io5";



/** local file import */
import classes from '../asset/css/PrepareContract.module.css'
import { useNavigate } from 'react-router-dom';
import { Images } from '../data/images';


/** code start */



const PrepareContract = ({ show, handleClose }) => {


    const navigate = useNavigate()

    const [stampUpload, setStampUpload] = useState('Click to upload')

    const [editFormStatus, SetEditFormStatus] = useState({
        name: false,
        address: false,
        phone: false,
        email: false,
        clientName: false,
        contactPerson: false,
        legalAddress: false,
        billingAddress: false,
        clientPhone: false,
        clientEmail: false,
        nameOfProgram: false,
        typeOfApplication: false,
        isccSystem: false,
        scope: false,
        siteLocation: false,
        certificationBody: false,

    })
    const [remarkView, setRemarkView] = useState(true)

    const stampUploadRef = useRef(null);

    const handleStampUpload = () => {
        stampUploadRef.current.click()
    }



    // accordion open close action

    function ContextAwareToggle({ children, eventKey, callback }) {
        const { activeEventKey } = useContext(AccordionContext);
        setRemarkView(activeEventKey)
        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = activeEventKey === eventKey;



        return (
            <button
                type="button"
                className='accordion-one__action'
                onClick={decoratedOnClick}
            >
                {isCurrentEventKey ? <FaMinus /> : <FaPlus />}
            </button>
        );
    }

    console.log('remark', remarkView)


    const handleReject = () => {
        handleClose()
    }

    const handleApprove = () => {
        handleClose()
        navigate('/thank-you')
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                size='xl'
                className={`${classes.customModalOne} customModalOne`}
            >
                {/* <Modal.Header closeButton>
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header> */}
                <Modal.Body className={`${classes.customModalOne_body}`}>
                    <button className={`${classes.close}`} onClick={handleClose}> <IoMdClose /></button>
                    <div className={`${classes.bodyInner}`}>
                        <div className={`${classes.header} row mx-auto`}>
                            <div className=' col-12 col-sm-7'>
                                <p className={`${classes.title}`}>
                                    Prepare Contract
                                </p>
                                <p className={`${classes.description} mt-1`}>
                                    (Please correct those highlighted with <span>**</span> before you can approve this application )
                                </p>
                            </div>
                            <div className='col-4 col-sm-2  d-flex justify-content-center mt-3 mt-sm-0 '>
                                <p className={`${classes.title} text-center`}>
                                    Check?
                                </p>
                            </div>
                            <div className={` ${remarkView !== null ? 'd-block' : "d-none"} col-3 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0`}>
                                <p className={`${classes.title} text-center`}>
                                    Remarks <br />
                                    (if any)
                                </p>
                            </div>
                        </div>
                        <div className={`${classes.cardGray} mt-3`}>
                            <Accordion defaultActiveKey="0" className='accordion-one'>
                                <Card>
                                    <Card.Header className='px-0'>
                                        <div className='row w-100 mx-auto'>
                                            <div className='col-12 col-sm-7'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <ContextAwareToggle eventKey="0" />
                                                    <div >
                                                        <p className='accordion-one__title'> Agreement-related parties</p>
                                                        {/* {clientInformationEdit ? <input className='accordion-one__title-input' /> : <p className='accordion-one__title'> 1. Information of Client</p>} */}
                                                    </div>

                                                    {/* <button className='d-flex aling-items-center justify-content-center' onClick={() => setClientInformationEdit(!clientInformationEdit)}>
                                                        {clientInformationEdit ? <IoClose className='accordion-one__title-edit' fontSize={25} /> : <RiPencilFill className='accordion-one__title-edit' fontSize={25} />}
                                                    </button> */}
                                                </div>
                                            </div>
                                            <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                <div style={{ height: "28px", width: "28px" }}>
                                                    <div className={`${classes.check_container}   green_check_container`}>
                                                        <label className={`${classes.check_margintop} checkbox_container`}>
                                                            <input type="checkbox" />
                                                            <span class="checkbox_checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                <input className='accordion-one__title-input' />
                                            </div> */}
                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body className='p-0'>
                                            <div className='row w-100 mx-auto align-items-center mt-1'>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>
                                                        <p className='accordion-one__collapse--value'>This agreement is made between:</p>
                                                        <div className='d-flex align-items-center gap-3 mt-3'>
                                                            <p className='accordion-one__collapse--title'>Name of the ceritification </p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, name: !editFormStatus?.name })}>
                                                                {editFormStatus?.name ? <IoClose className='accordion-one__title-edit' fontSize={28} /> : <RiPencilFill className='accordion-one__title-edit' fontSize={25} />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.name ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>PT Qualitas Sertifikasi Indonesia</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Address</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, address: !editFormStatus?.address })}>
                                                                {editFormStatus?.address ? <IoClose className='accordion-one__title-edit' fontSize={28} /> : <RiPencilFill className='accordion-one__title-edit' fontSize={25} />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.address ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>Gedung The CEO, Lantai 12, Jl. TB Simatupang No. 18C, Kel. <br />Cilandak Barat, Kec. Cilandak, Jakarta Selatan, DKI Jakarta 12430</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Phone</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, phone: !editFormStatus?.phone })}>
                                                                {editFormStatus?.phone ? <IoClose className='accordion-one__title-edit' fontSize={28} /> : <RiPencilFill className='accordion-one__title-edit' fontSize={25} />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.phone ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>(021) 29491946</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>E-mail</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, email: !editFormStatus?.email })}>
                                                                {editFormStatus?.email ? <IoClose className='accordion-one__title-edit' fontSize={28} /> : <RiPencilFill className='accordion-one__title-edit' fontSize={25} />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.email ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value text-decoration-underline'>admin@qualitasertifikasi.com</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>

                                            <p className='accordion-one__collapse--value pt-4 px-3 px-sm-5'>As a party that provides certification services for ISCC</p>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Client's Name</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, clientName: !editFormStatus?.clientName })}>
                                                                {editFormStatus?.clientName ? <IoClose className='accordion-one__title-edit' fontSize={28} /> : <RiPencilFill className='accordion-one__title-edit' fontSize={25} />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.clientName ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>Genuine Ventures</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>ISCC Contact Person</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, contactPerson: !editFormStatus?.contactPerson })}>
                                                                {editFormStatus?.contactPerson ? <IoClose className='accordion-one__title-edit' fontSize={28} /> : <RiPencilFill className='accordion-one__title-edit' fontSize={25} />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.contactPerson ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>Dr. Patrick Chin</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Legal Address</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, legalAddress: !editFormStatus?.legalAddress })}>
                                                                {editFormStatus?.legalAddress ? <IoClose className='accordion-one__title-edit' fontSize={28} /> : <RiPencilFill className='accordion-one__title-edit' fontSize={25} />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.legalAddress ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>188/1108, Airlink Park, Romklao Road, Klongsampravet, Ladkrabang, Bangkok, 10520</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Billing Address</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, billingAddress: !editFormStatus?.billingAddress })}>
                                                                {editFormStatus?.billingAddress ? <IoClose className='accordion-one__title-edit' fontSize={28} /> : <RiPencilFill className='accordion-one__title-edit' fontSize={25} />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.billingAddress ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>188/1108, Airlink Park, Romklao Road, Klongsampravet, Ladkrabang, Bangkok, 10520</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Phone</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, clientPhone: !editFormStatus?.clientPhone })}>
                                                                {editFormStatus?.clientPhone ? <IoClose className='accordion-one__title-edit' fontSize={28} /> : <RiPencilFill className='accordion-one__title-edit' fontSize={25} />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.clientPhone ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>(065) 80319250</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Email</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, clientEmail: !editFormStatus?.clientEmail })}>
                                                                {editFormStatus?.clientEmail ? <IoClose className='accordion-one__title-edit' fontSize={28} /> : <RiPencilFill className='accordion-one__title-edit' fontSize={25} />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.clientEmail ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value text-decoration-underline'>patrick@genuineventures.co.th</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <p className='accordion-one__collapse--value px-3 px-sm-5 pt-4'>As the party receiving ISCC ceritication services</p>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className='mt-4'>
                                    <Card.Header className='px-0'>
                                        <div className='row w-100 mx-auto'>
                                            <div className='col-12 col-sm-7'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <ContextAwareToggle eventKey="1" />
                                                    <div >
                                                        <p className='accordion-one__title'> 2. Detail information on the ceritification activity</p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                <div style={{ height: "28px", width: "28px" }}>
                                                    <div className={`${classes.check_container}   green_check_container`}>
                                                        <label className={`${classes.check_margintop} checkbox_container`}>
                                                            <input type="checkbox" />
                                                            <span class="checkbox_checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body className='p-0'>
                                            <div className='row w-100 mx-auto align-items-center mt-1'>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Name of Program</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, nameOfProgram: !editFormStatus?.nameOfProgram })}>
                                                                {editFormStatus?.nameOfProgram ? <IoClose className='accordion-one__title-edit' fontSize={28} /> : <RiPencilFill className='accordion-one__title-edit' fontSize={25} />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.nameOfProgram ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>International Sustainablity and Carbon Certification</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Type of Application</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, typeOfApplication: !editFormStatus?.typeOfApplication })}>
                                                                {editFormStatus?.typeOfApplication ? <IoClose className='accordion-one__title-edit' fontSize={28} /> : <RiPencilFill className='accordion-one__title-edit' fontSize={25} />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.typeOfApplication ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>Online</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>ISCC System</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, isccSystem: !editFormStatus?.isccSystem })}>
                                                                {editFormStatus?.isccSystem ? <IoClose className='accordion-one__title-edit' fontSize={28} /> : <RiPencilFill className='accordion-one__title-edit' fontSize={25} />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.isccSystem ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>ISCC EU</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Scope</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, scope: !editFormStatus?.scope })}>
                                                                {editFormStatus?.scope ? <IoClose className='accordion-one__title-edit' fontSize={28} /> : <RiPencilFill className='accordion-one__title-edit' fontSize={25} />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.scope ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>Used Cooking Oil(UCO)</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>

                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Site Location</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, siteLocation: !editFormStatus?.siteLocation })}>
                                                                {editFormStatus?.siteLocation ? <IoClose className='accordion-one__title-edit' fontSize={28} /> : <RiPencilFill className='accordion-one__title-edit' fontSize={25} />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.siteLocation ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>188/1108, Airlink Park, Romklao Road, Klongsampravet, Ladkrabang, Bangkok, 10520</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Certification body</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, certificationBody: !editFormStatus?.certificationBody })}>
                                                                {editFormStatus?.certificationBody ? <IoClose className='accordion-one__title-edit' fontSize={28} /> : <RiPencilFill className='accordion-one__title-edit' fontSize={25} />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.certificationBody ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>PT Qualitas Sertifikasi Indonesia</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <p className='accordion-one__collapse--value px-3 px-sm-5 pt-4'>All related procedural documents and records will be assessed by PT QSI. The following is the estimation schedule for the implementation of the certification activity that will be carried out:(September, 2024)</p>

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className='mt-4'>
                                    <Card.Header className='px-0'>

                                        <div className='row w-100 mx-auto'>
                                            <div className='col-12 col-sm-7'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <ContextAwareToggle eventKey="2" />
                                                    <div >
                                                        <p className='accordion-one__title'> 3. Calculation of the required assessment time / man-day  <span className={`${classes.highlightText}`}>**</span> </p>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                <div style={{ height: "28px", width: "28px" }}>
                                                    <div className={`${classes.check_container}   green_check_container`}>
                                                        <label className={`${classes.check_margintop} checkbox_container`}>
                                                            <input type="checkbox" />
                                                            <span class="checkbox_checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body className='p-0'>
                                            <div>
                                                <p className='accordion-one__collapse--title fw-bold px-3 px-sm-5 pt-3 '>Main assessment activities (initial assessment or recertification)</p>
                                                <div className='row w-100 mx-auto align-items-center mt-4'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>
                                                        <p className='accordion-one__collapse--title'>Activites</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-2 d-flex justify-content-center'>
                                                        <p className='accordion-one__collapse--title'>Man-Day</p>
                                                    </div>
                                                </div>
                                                <div className='row w-100 mx-auto align-items-center mt-3'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>

                                                        <p className='accordion-one__collapse--value'>A. Traveling days</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-2 d-flex justify-content-center'>
                                                        <input className='accordion-one__input--bg' />
                                                    </div>
                                                </div>

                                                <div className='row w-100 mx-auto align-items-center mt-3'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>

                                                        <p className='accordion-one__collapse--value'>B. Main Assessment</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-2 d-flex justify-content-center '>
                                                        <input className='accordion-one__input--bg' />
                                                    </div>
                                                </div>
                                                <div className='row w-100 mx-auto align-items-center mt-3'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>

                                                        <p className='accordion-one__collapse--value'>C. Reporting, coordination, and review</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-2 d-flex justify-content-center '>
                                                        <input className='accordion-one__input--bg' />
                                                    </div>
                                                </div>
                                                <div className='row w-100 mx-auto align-items-center mt-3'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>

                                                        <p className='accordion-one__collapse--value fw-bold'>Total Days (A+B+C)</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-2 d-flex justify-content-center'>
                                                        <input className='accordion-one__input--bg' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mt-4'>
                                                <p className='accordion-one__collapse--title fw-bold px-3 px-sm-5 pt-3 '>Surveillance assessment activities</p>
                                                <div className='row w-100 mx-auto align-items-center mt-4'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>
                                                        <p className='accordion-one__collapse--title'>Activites</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-2 d-flex justify-content-center'>
                                                        <p className='accordion-one__collapse--title'>Man-Day</p>
                                                    </div>
                                                </div>
                                                <div className='row w-100 mx-auto align-items-center mt-3'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>

                                                        <p className='accordion-one__collapse--value'>A. Traveling days</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-2 d-flex justify-content-center'>
                                                        <input className='accordion-one__input--bg' />
                                                    </div>
                                                </div>

                                                <div className='row w-100 mx-auto align-items-center mt-3'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>

                                                        <p className='accordion-one__collapse--value'>B. Surveillance assessment</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-2 d-flex justify-content-center '>
                                                        <input className='accordion-one__input--bg' />
                                                    </div>
                                                </div>
                                                <div className='row w-100 mx-auto align-items-center mt-3'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>

                                                        <p className='accordion-one__collapse--value'>C. Reporting, coordination, and review</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-2 d-flex justify-content-center '>
                                                        <input className='accordion-one__input--bg' />
                                                    </div>
                                                </div>
                                                <div className='row w-100 mx-auto align-items-center mt-3'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>

                                                        <p className='accordion-one__collapse--value fw-bold'>Total Days (A+B+C)</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-2 d-flex justify-content-center'>
                                                        <input className='accordion-one__input--bg' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mt-4'>
                                                <p className='accordion-one__collapse--title fw-bold px-3 px-sm-5 pt-3 '>Sample checks</p>
                                                <div className='row w-100 mx-auto align-items-center mt-4'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>
                                                        <p className='accordion-one__collapse--title'>Type of Sample</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-4 d-flex justify-content-center'>
                                                        <p className='accordion-one__collapse--title'>Number of companies / Locations to be sampled*</p>
                                                    </div>
                                                </div>
                                                <div className='row w-100 mx-auto align-items-center mt-3'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>

                                                        <p className='accordion-one__collapse--value'>A. Supplying farms</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-2 d-flex justify-content-center'>
                                                        <input className='accordion-one__input--bg' />
                                                    </div>
                                                </div>

                                                <div className='row w-100 mx-auto align-items-center mt-3'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>

                                                        <p className='accordion-one__collapse--value'>B. Points of Origins &gt; 5 MT capacity/month</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-2 d-flex justify-content-center '>
                                                        <input className='accordion-one__input--bg' />
                                                    </div>
                                                </div>
                                                <div className='row w-100 mx-auto align-items-center mt-3'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>

                                                        <p className='accordion-one__collapse--value'>C. Points of Origins &lt; 5 MT capacity/month**</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-2 d-flex justify-content-center '>
                                                        <input className='accordion-one__input--bg' />
                                                    </div>
                                                </div>
                                                <div className='row w-100 mx-auto align-items-center mt-3'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>

                                                        <p className='accordion-one__collapse--value'>D. Warehouse</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-2 d-flex justify-content-center '>
                                                        <input className='accordion-one__input--bg' />
                                                    </div>
                                                </div>
                                                <div className='row w-100 mx-auto align-items-center mt-3'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>

                                                        <p className='accordion-one__collapse--value'>E. Dependant Collecting Points</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-2 d-flex justify-content-center '>
                                                        <input className='accordion-one__input--bg' />
                                                    </div>
                                                </div>
                                                <div className='row w-100 mx-auto align-items-center mt-3'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>

                                                        <p className='accordion-one__collapse--value fw-bold'>Total Days (A+B+C)</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-2 d-flex justify-content-center'>
                                                        <input className='accordion-one__input--bg' />
                                                    </div>
                                                </div>
                                            </div>
                                            <p className='accordion-one__collapse--value px-3 px-sm-5 pt-4'>*these numbers are based on the filled application form you provided, in future ceritication cycles these numbers may differ but the day rate will apply for the
                                                number of days required.
                                            </p>
                                            <p className='accordion-one__collapse--value px-3 px-sm-5 pt-2'>**for this category, the square root of companies is subject to a remote "verification of existence" according to ISCC requirements</p>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className='mt-4'>
                                    <Card.Header className='px-0'>

                                        <div className='row w-100 mx-auto'>
                                            <div className='col-12 col-sm-7'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <ContextAwareToggle eventKey="3" />
                                                    <div >
                                                        <p className='accordion-one__title'> 4. Fee (excl. VAT/Tax) <span className={`${classes.highlightText}`}>**</span></p>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                <div style={{ height: "28px", width: "28px" }}>
                                                    <div className={`${classes.check_container}   green_check_container`}>
                                                        <label className={`${classes.check_margintop} checkbox_container`}>
                                                            <input type="checkbox" />
                                                            <span class="checkbox_checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="3">
                                        <Card.Body className='p-0'>
                                            <div>
                                                <div className='row w-100 mx-auto align-items-center mt-3'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>
                                                        <p className='accordion-one__collapse--title'>Activites</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-2 d-flex justify-content-center'>
                                                        <p className='accordion-one__collapse--title'>Cost (Rp)</p>
                                                    </div>
                                                </div>
                                                <div className='row w-100 mx-auto align-items-center mt-3'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>

                                                        <p className='accordion-one__collapse--value'>A. Audit Activities</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-2 d-flex justify-content-center'>
                                                        <input className='accordion-one__input--bg' />
                                                    </div>
                                                </div>

                                                <div className='row w-100 mx-auto align-items-center mt-3'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>

                                                        <p className='accordion-one__collapse--value'>B. Review,Certification Decision & Coordination</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-2 d-flex justify-content-center '>
                                                        <input className='accordion-one__input--bg' />
                                                    </div>
                                                </div>

                                                <div className='row w-100 mx-auto align-items-center mt-3'>
                                                    <div className='col-6 col-sm-6 col-md-7 px-3 px-sm-5'>

                                                        <p className='accordion-one__collapse--value fw-bold'>Total Days (A+B)</p>
                                                    </div>
                                                    <div className='col-6 col-sm-6 col-md-3 col-xl-2 d-flex justify-content-center'>
                                                        <input className='accordion-one__input--bg' />
                                                    </div>
                                                </div>
                                            </div>
                                            <p className='accordion-one__collapse--value px-3 px-sm-5 pt-4'>* Accommodation & Travel costs do not include the costs mentioned above <br />
                                                * Audit activities include surveillance audit if required based on ISCC requirements (3 and/or 6 months after initial assessment) <br />
                                                (ref:ISCC Fees document dated 01 September 2022)
                                                ** Exclude tax (11%)
                                            </p>

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className='mt-4'>
                                    <Card.Header className='px-0'>

                                        <div className='row w-100 mx-auto'>
                                            <div className='col-12 col-sm-7'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <ContextAwareToggle eventKey="4" />
                                                    <div >
                                                        <p className='accordion-one__title'> 5. Terms and Condition Payment </p>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                <div style={{ height: "28px", width: "28px" }}>
                                                    <div className={`${classes.check_container}   green_check_container`}>
                                                        <label className={`${classes.check_margintop} checkbox_container`}>
                                                            <input type="checkbox" />
                                                            <span class="checkbox_checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="4">
                                        <Card.Body className='p-0'>
                                            <div className=''>
                                                <p className='accordion-one__collapse--value px-3 px-sm-5 pt-4'>* a. The audit fee as stated in the invoice is fully
                                                    paid before the assessment and is carried out in stages according to the type of audit to be carried out
                                                    (for example initial certification audit, surveillance, or recertification), regardless of the result of the assessment.
                                                </p>
                                                <p className='accordion-one__collapse--value px-3 px-sm-5 pt-3'>b. Repayment of assessment is transferred to the account of PT QSI listed on the invoice.
                                                </p>
                                                <p className='accordion-one__collapse--value px-3 px-sm-5 pt-3'>
                                                    c. The above costs do not include additional assessment fees (if required), which can beincurred for the purpose of verifying corrections
                                                    of non-conformities that occurred during the assessment, and/or a special assessment is required.
                                                </p>
                                                <p className='accordion-one__collapse--value px-3 px-sm-5 pt-3'>
                                                    d. The above costs do not include:

                                                </p>
                                                <p className='accordion-one__collapse--value px-3 px-sm-5 pt-4'>
                                                    -Taxes
                                                </p>
                                                <p className='accordion-one__collapse--value px-3 px-sm-5 pt-2'>
                                                    -ISCC certification fee
                                                </p>
                                                <p className='accordion-one__collapse--value px-3 px-sm-5 pt-2'>
                                                    -Travel
                                                </p>
                                                <p className='accordion-one__collapse--value px-3 px-sm-5 pt-2'>
                                                    -Accommaodation
                                                </p>
                                                <p className='accordion-one__collapse--value px-3 px-sm-5 pt-2'>
                                                    - Fee for ISCC licensees based on ISCC Fees document dated 01 September 2022 (If applicable)
                                                </p>
                                                <p className='accordion-one__collapse--value px-3 px-sm-5 pt-2'>
                                                    - Visa making (if applicable)
                                                </p>
                                                <p className='accordion-one__collapse--value px-3 px-sm-5 pt-2'>
                                                    - PCR/SWAB/Quarantine (if applicable)
                                                </p>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className='mt-4'>
                                    <Card.Header className='px-0'>

                                        <div className='row w-100 mx-auto'>
                                            <div className='col-12 col-sm-7'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <ContextAwareToggle eventKey="5" />
                                                    <div >
                                                        <p className='accordion-one__title'> 6. Validity of Agreement <span className={`${classes.highlightText}`}>**</span></p>
                                                    </div>


                                                </div>
                                            </div>
                                            <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                <div style={{ height: "28px", width: "28px" }}>
                                                    <div className={`${classes.check_container}   green_check_container`}>
                                                        <label className={`${classes.check_margintop} checkbox_container`}>
                                                            <input type="checkbox" />
                                                            <span class="checkbox_checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="5">
                                        <Card.Body className='p-0'>
                                            <div className=''>
                                                <p className='accordion-one__collapse--value px-3 px-sm-5 pt-4'>This Agreement is only valid if it has been signed by authorized person from Client.
                                                </p>
                                                <p className='accordion-one__collapse--value px-3 px-sm-5 pt-3'>
                                                    This Agreement remains valid for 3 (three) months from the issuance date by PT QSI.
                                                </p>
                                                <p className='accordion-one__collapse--value px-3 px-sm-5 pt-3'>
                                                    If the Client has not signed this Agreement within a period of more than 3 (three) months from the issuance date by PT QSI, then PT QSI will issue a new Agreement.
                                                    The new Agreement will replace and eliminate the previous Agreement issued by PT QSI.  The terms and conditions is inseparable part of this agreement
                                                </p>

                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className='mt-4'>
                                    <Card.Header className='px-0'>

                                        <div className='row w-100 mx-auto'>
                                            <div className='col-12 col-sm-7'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <ContextAwareToggle eventKey="6" />
                                                    <div >
                                                        <p className='accordion-one__title'> 7. Attestation <span className={`${classes.highlightText}`}>**</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                <div style={{ height: "28px", width: "28px" }}>
                                                    <div className={`${classes.check_container}   green_check_container`}>
                                                        <label className={`${classes.check_margintop} checkbox_container`}>
                                                            <input type="checkbox" />
                                                            <span class="checkbox_checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="6">
                                        <Card.Body className='p-0'>
                                            <div className=''>
                                                <p className='accordion-one__collapse--value px-3 px-sm-5 pt-3'>On behalf of PT Qualitas Sertifikasi Indonesia
                                                </p>
                                                <div className='row px-3 px-sm-5 pt-3'>
                                                    <div className='col-12 col-md-5'>
                                                        <p className='accordion-one__collapse--value '>Name
                                                        </p>
                                                    </div>
                                                    <div className='col-12 col-md-7 mt-2 mt-md-0'>
                                                        <p className='accordion-one__collapse--value '>Kendty Danang Prayogi
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='row px-3 px-sm-5 pt-3'>
                                                    <div className='col-12 col-md-5'>
                                                        <p className='accordion-one__collapse--value '>Function
                                                        </p>
                                                    </div>
                                                    <div className='col-12 col-md-7 mt-2 mt-md-0'>
                                                        <p className='accordion-one__collapse--value '>Operational Manager on behalf of the Director
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='row px-3 px-sm-5 pt-3'>
                                                    <div className='col-12 col-md-5'>
                                                        <p className='accordion-one__collapse--value '>Date
                                                        </p>
                                                    </div>
                                                    <div className='col-12 col-md-7 mt-2 mt-md-0'>
                                                        <p className='accordion-one__collapse--value '>10th August 2024
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='row px-3 px-sm-5 pt-3'>
                                                    <div className='col-12 col-md-5'>
                                                        <p className='accordion-one__collapse--value '>Signature and Stamp (Sign inside box (or) upload stamp with signature)
                                                        </p>
                                                    </div>
                                                    <div className='col-12 col-md-7 mt-2 mt-md-0'>
                                                        <div className={`${classes.stamp_upload}`} onClick={handleStampUpload}>
                                                            <p className={`${classes.regular_text}`}>{stampUpload}</p>
                                                            <input ref={stampUploadRef} type='file' className='d-none' onChange={(e) => setStampUpload(e.target.files[0].name)} />
                                                            <img src={Images.formUpload} className='img-fluid' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className='mt-4'>
                                    <Card.Header className='px-0'>

                                        <div className='row w-100 mx-auto'>
                                            <div className='col-12 col-sm-7'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <ContextAwareToggle eventKey="7" />
                                                    <div >
                                                        <p className='accordion-one__title'> 8. Terms and Conditions of Certification Services</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                <div style={{ height: "28px", width: "28px" }}>
                                                    <div className={`${classes.check_container}   green_check_container`}>
                                                        <label className={`${classes.check_margintop} checkbox_container`}>
                                                            <input type="checkbox" />
                                                            <span class="checkbox_checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="7">
                                        <Card.Body className='p-0'>

                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>



                        </div>
                        <div className='mt-5 px-3'>
                            <p className='accordion-one__title'>Additional Remarks</p>
                            <textarea className={`${classes.textarea}`} />
                        </div>
                        <div className='row mt-5'>
                            <div className='col-6 d-flex justify-content-end'>
                                <button className={`${classes.formButton}`} onClick={handleReject}>Reject</button>
                            </div>
                            <div className='col-6 d-flex justify-content-start'>
                                <button className={`${classes.formButton}`} onClick={handleApprove}>Approve</button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                {/* <Modal.Footer>
                    <button onClick={handleClose}>Close</button>
                </Modal.Footer> */}
            </Modal >
        </>
    )
}

export default PrepareContract