/** npm import */
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';
import { IoMdClose } from "react-icons/io";
import { FaPlus, FaMinus } from "react-icons/fa";
import { RiPencilFill } from "react-icons/ri";
import { IoClose } from "react-icons/io5";



/** local file import */
import classes from '../asset/css/ApplicationReviewModal.module.css'
import { useNavigate } from 'react-router-dom';


/** code start */



const ApplicationReview = ({ show, handleClose }) => {


    const navigate = useNavigate()


    const [clientInformationEdit, setClientInformationEdit] = useState(false)

    const [editFormStatus, SetEditFormStatus] = useState({
        name: false,
        address: false,
        siteAddress: false,
        billingAddress: false,
        geoCoordinates: false,
        contactPerson: false,
        registrationNumber: false,
        vatNumber: false,
        scopeOfAudit: false,
        typeOfApplication: false,
        previousCeritificationBody: false,
        isccSystem: false,
        sustainableInputMaterial: false,
        sustainableOuputMaterial: false,
        ghgCalculation: false,
        internalStorage: false,
        externalStorage: false,
        otherSustainablityCertification: false,
        dependantCollectingPoint: false,
        supplierLess5: false,
        supplierGreat5: false,
        voluntaryAddOn: false,
        companyProfile: false,
        listOfDepartments: false,
        travelTime: false,
        potentialConflict: false
    })
    const [remarkView, setRemarkView] = useState(true)



    // accordion open close action

    function ContextAwareToggle({ children, eventKey, callback }) {
        const { activeEventKey } = useContext(AccordionContext);
        setRemarkView(activeEventKey)
        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = activeEventKey === eventKey;



        return (
            <button
                type="button"
                className='accordion-one__action'
                onClick={decoratedOnClick}
            >
                {isCurrentEventKey ? <FaMinus /> : <FaPlus />}
            </button>
        );
    }

    console.log('remark', remarkView)


    const handleReject = () => {
        handleClose()
    }

    const handleApprove = () => {
        handleClose()
        navigate('/thank-you')
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                size='xl'
                className={`${classes.customModalOne} customModalOne`}
            >
                {/* <Modal.Header closeButton>
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header> */}
                <Modal.Body className={`${classes.customModalOne_body}`}>
                    <button className={`${classes.close}`} onClick={handleClose}> <IoMdClose /></button>
                    <div className={`${classes.bodyInner}`}>
                        <div className={`${classes.header} row mx-auto`}>
                            <div className=' col-12 col-sm-7 '>
                                <p className={`${classes.title}`}>
                                    Application Review
                                </p>
                                <p className={`${classes.description} mt-1`}>
                                    (Please correct those highlighted with <span>**</span> before you can approve this application )
                                </p>
                            </div>
                            <div className='col-4 col-sm-2  d-flex justify-content-center mt-3 mt-sm-0 '>
                                <p className={`${classes.title} text-center`}>
                                    Check?
                                </p>
                            </div>
                            <div className={` ${remarkView !== null ? 'd-block' : "d-none"} col-3 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0`}>
                                <p className={`${classes.title} text-center`}>
                                    Remarks <br />
                                    (if any)
                                </p>
                            </div>
                        </div>
                        <div className={`${classes.cardGray} mt-3`}>
                            <Accordion defaultActiveKey="0" className='accordion-one'>
                                <Card>
                                    <Card.Header className='px-0'>
                                        <div className='row w-100 mx-auto'>
                                            <div className='col-12 col-sm-7 '>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <ContextAwareToggle eventKey="0" />
                                                    <div >
                                                        <p className='accordion-one__title'> 1. Information of Client</p>
                                                        {/* {clientInformationEdit ? <input className='accordion-one__title-input' /> : <p className='accordion-one__title'> 1. Information of Client</p>} */}
                                                    </div>

                                                    {/* <button className='d-flex aling-items-center justify-content-center' onClick={() => setClientInformationEdit(!clientInformationEdit)}>
                                                        {clientInformationEdit ? <IoClose className='accordion-one__title-edit'  /> : <RiPencilFill className='accordion-one__title-edit'  />}
                                                    </button> */}
                                                </div>
                                            </div>
                                            <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                <div style={{ height: "28px", width: "28px" }}>
                                                    <div className={`${classes.check_container}   green_check_container`}>
                                                        <label className={`${classes.check_margintop} checkbox_container`}>
                                                            <input type="checkbox" />
                                                            <span class="checkbox_checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                <input className='accordion-one__title-input' />
                                            </div> */}
                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body className='p-0'>
                                            <div className='row w-100 mx-auto align-items-center mt-1'>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Name of the ceritification body</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, name: !editFormStatus?.name })}>
                                                                {editFormStatus?.name ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.name ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>PT Qualitas Sertifikasi Indonesia</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Legal Address</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, address: !editFormStatus?.address })}>
                                                                {editFormStatus?.address ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.address ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>Gedung The CEO, Lantai 12, Jl. TB Simatupang No. 18C, Kel. <br />Cilandak Barat, Kec. Cilandak, Jakarta Selatan, DKI Jakarta 1</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Site Address</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, siteAddress: !editFormStatus?.siteAddress })}>
                                                                {editFormStatus?.siteAddress ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.siteAddress ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>Gedung The CEO, Lantai 12, Jl. TB Simatupang No. 18C, Kel. <br />Cilandak Barat, Kec. Cilandak, Jakarta Selatan, DKI Jakarta 12430</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Billing Address</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, billingAddress: !editFormStatus?.billingAddress })}>
                                                                {editFormStatus?.billingAddress ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.billingAddress ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>Gedung The CEO, Lantai 12, Jl. TB Simatupang No. 18C, Kel.<br />Cilandak Barat, Kec. Cilandak, Jakarta Selatan, DKI Jakarta 12430</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Geo-Coordinates</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, geoCoordinates: !editFormStatus?.geoCoordinates })}>
                                                                {editFormStatus?.geoCoordinates ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className='row'>
                                                            <div className='col-6'>
                                                                <p className='accordion-one__collapse--value'>Latitude In</p>
                                                                {editFormStatus?.geoCoordinates ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>13.736541</p>}
                                                            </div>
                                                            <div className='col-6'>
                                                                <p className='accordion-one__collapse--value'>Longitude In</p>
                                                                {editFormStatus?.geoCoordinates ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>100.745887</p>}
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>ISCC Contact Person</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, contactPerson: !editFormStatus?.contactPerson })}>
                                                                {editFormStatus?.contactPerson ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.contactPerson ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>Dr. Patrick Chin</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>ISCC Registration Number</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, registrationNumber: !editFormStatus?.registrationNumber })}>
                                                                {editFormStatus?.registrationNumber ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.registrationNumber ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>VAT Number</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, vatNumber: !editFormStatus?.vatNumber })}>
                                                                {editFormStatus?.vatNumber ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.vatNumber ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className='mt-4'>
                                    <Card.Header className='px-0'>
                                        <div className='row w-100 mx-auto'>
                                            <div className='col-12 col-sm-7'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <ContextAwareToggle eventKey="1" />
                                                    <div >
                                                        <p className='accordion-one__title'> 2. Relevant Information</p>
                                                        {/* {clientInformationEdit ? <input className='accordion-one__title-input' /> : <p className='accordion-one__title'> 1. Information of Client</p>} */}
                                                    </div>

                                                    {/* <button className='d-flex aling-items-center justify-content-center' onClick={() => setClientInformationEdit(!clientInformationEdit)}>
                                                        {clientInformationEdit ? <IoClose className='accordion-one__title-edit'  /> : <RiPencilFill className='accordion-one__title-edit'  />}
                                                    </button> */}
                                                </div>
                                            </div>
                                            <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                <div style={{ height: "28px", width: "28px" }}>
                                                    <div className={`${classes.check_container}   green_check_container`}>
                                                        <label className={`${classes.check_margintop} checkbox_container`}>
                                                            <input type="checkbox" />
                                                            <span class="checkbox_checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                <input className='accordion-one__title-input' />
                                            </div> */}
                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="1">
                                        <Card.Body className='p-0'>
                                            <div className='row w-100 mx-auto align-items-center mt-1'>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Scope of audit</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, scopeOfAudit: !editFormStatus?.scopeOfAudit })}>
                                                                {editFormStatus?.scopeOfAudit ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.scopeOfAudit ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>Used Cooking Oil(UCO)</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5' >
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Type of Application</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, typeOfApplication: !editFormStatus?.typeOfApplication })}>
                                                                {editFormStatus?.typeOfApplication ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.typeOfApplication ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>Online</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Previous Certification Body(Only applicable for recertification)</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, previousCeritificationBody: !editFormStatus?.previousCeritificationBody })}>
                                                                {editFormStatus?.previousCeritificationBody ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.previousCeritificationBody ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>ISCC</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>ISCC System</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, isccSystem: !editFormStatus?.isccSystem })}>
                                                                {editFormStatus?.isccSystem ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.isccSystem ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>ISCC - EU</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>

                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Sustainable input material(s)</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, sustainableInputMaterial: !editFormStatus?.sustainableInputMaterial })}>
                                                                {editFormStatus?.sustainableInputMaterial ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.sustainableInputMaterial ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Sustainable output material(s)</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, sustainableOuputMaterial: !editFormStatus?.sustainableOuputMaterial })}>
                                                                {editFormStatus?.sustainableOuputMaterial ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.sustainableOuputMaterial ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>GHG Calculation</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, ghgCalculation: !editFormStatus?.ghgCalculation })}>
                                                                {editFormStatus?.ghgCalculation ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.ghgCalculation ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Internal Storage</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, internalStorage: !editFormStatus?.internalStorage })}>
                                                                {editFormStatus?.internalStorage ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.internalStorage ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>External Storage</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, externalStorage: !editFormStatus?.externalStorage })}>
                                                                {editFormStatus?.externalStorage ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.externalStorage ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Other Sustainability Certification</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, otherSustainablityCertification: !editFormStatus?.otherSustainablityCertification })}>
                                                                {editFormStatus?.otherSustainablityCertification ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.otherSustainablityCertification ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Dependant Collecting Point</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, dependantCollectingPoint: !editFormStatus?.dependantCollectingPoint })}>
                                                                {editFormStatus?.dependantCollectingPoint ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.dependantCollectingPoint ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Supplier N  &lt; 5 MT</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, supplierLess5: !editFormStatus?.supplierLess5 })}>
                                                                {editFormStatus?.supplierLess5 ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.supplierLess5 ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Supplier N  &gt; 5 MT</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, supplierGreat5: !editFormStatus?.supplierGreat5 })}>
                                                                {editFormStatus?.supplierGreat5 ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.supplierGreat5 ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4 '>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Voluntary add-ons (only applicable for ISCC PLUS)</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, voluntaryAddOn: !editFormStatus?.voluntaryAddOn })}>
                                                                {editFormStatus?.voluntaryAddOn ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>
                                                            {editFormStatus?.voluntaryAddOn ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className='mt-4'>
                                    <Card.Header className='px-0'>
                                        <div className='row w-100 mx-auto'>
                                            <div className='col-12 col-sm-7'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <ContextAwareToggle eventKey="2" />
                                                    <div >
                                                        <p className='accordion-one__title'> 2. Other Information</p>
                                                        {/* {clientInformationEdit ? <input className='accordion-one__title-input' /> : <p className='accordion-one__title'> 1. Information of Client</p>} */}
                                                    </div>

                                                    {/* <button className='d-flex aling-items-center justify-content-center' onClick={() => setClientInformationEdit(!clientInformationEdit)}>
                                                        {clientInformationEdit ? <IoClose className='accordion-one__title-edit'  /> : <RiPencilFill className='accordion-one__title-edit'  />}
                                                    </button> */}
                                                </div>
                                            </div>
                                            <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                <div style={{ height: "28px", width: "28px" }}>
                                                    <div className={`${classes.check_container}   green_check_container`}>
                                                        <label className={`${classes.check_margintop} checkbox_container`}>
                                                            <input type="checkbox" />
                                                            <span class="checkbox_checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                <input className='accordion-one__title-input' />
                                            </div> */}
                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="2">
                                        <Card.Body className='p-0'>
                                            <div className='row w-100 mx-auto align-items-center mt-1'>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Description of Company Profile</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, companyProfile: !editFormStatus?.companyProfile })}>
                                                                {editFormStatus?.companyProfile ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.companyProfile ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4'>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>List of Departments</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, listOfDepartments: !editFormStatus?.listOfDepartments })}>
                                                                {editFormStatus?.listOfDepartments ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.listOfDepartments ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>Empty</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4'>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Travel time to the location from Jakarta</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, travelTime: !editFormStatus?.travelTime })}>
                                                                {editFormStatus?.travelTime ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.travelTime ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4'>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>If there is a potential conflict of interest, has it been 3 years?</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, potentialConflict: !editFormStatus?.potentialConflict })}>
                                                                {editFormStatus?.potentialConflict ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.potentialConflict ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card className='mt-4'>
                                    <Card.Header className='px-0'>
                                        <div className='row w-100 mx-auto'>
                                            <div className='col-12 col-sm-7'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <ContextAwareToggle eventKey="3" />
                                                    <div >
                                                        <p className='accordion-one__title'> 4. Risk Assessment</p>
                                                        {/* {clientInformationEdit ? <input className='accordion-one__title-input' /> : <p className='accordion-one__title'> 1. Information of Client</p>} */}
                                                    </div>

                                                    {/* <button className='d-flex aling-items-center justify-content-center' onClick={() => setClientInformationEdit(!clientInformationEdit)}>
                                                        {clientInformationEdit ? <IoClose className='accordion-one__title-edit'  /> : <RiPencilFill className='accordion-one__title-edit'  />}
                                                    </button> */}
                                                </div>
                                            </div>
                                            <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                <div style={{ height: "28px", width: "28px" }}>
                                                    <div className={`${classes.check_container}   green_check_container`}>
                                                        <label className={`${classes.check_margintop} checkbox_container`}>
                                                            <input type="checkbox" />
                                                            <span class="checkbox_checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                <input className='accordion-one__title-input' />
                                            </div> */}
                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="3">
                                        <Card.Body className='p-0'>
                                            <div className='row w-100 mx-auto align-items-center mt-1'>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Description of Company Profile</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, companyProfile: !editFormStatus?.companyProfile })}>
                                                                {editFormStatus?.companyProfile ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.companyProfile ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4'>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>List of Departments</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, listOfDepartments: !editFormStatus?.listOfDepartments })}>
                                                                {editFormStatus?.listOfDepartments ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.listOfDepartments ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>Empty</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4'>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Travel time to the location from Jakarta</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, travelTime: !editFormStatus?.travelTime })}>
                                                                {editFormStatus?.travelTime ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.travelTime ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4'>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>If there is a potential conflict of interest, has it been 3 years?</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, potentialConflict: !editFormStatus?.potentialConflict })}>
                                                                {editFormStatus?.potentialConflict ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.potentialConflict ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card className='mt-4'>
                                    <Card.Header className='px-0'>
                                        <div className='row w-100 mx-auto'>
                                            <div className='col-12 col-sm-7 '>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <ContextAwareToggle eventKey="4" />
                                                    <div >
                                                        <p className='accordion-one__title'> 5. Decision</p>
                                                        {/* {clientInformationEdit ? <input className='accordion-one__title-input' /> : <p className='accordion-one__title'> 1. Information of Client</p>} */}
                                                    </div>

                                                    {/* <button className='d-flex aling-items-center justify-content-center' onClick={() => setClientInformationEdit(!clientInformationEdit)}>
                                                        {clientInformationEdit ? <IoClose className='accordion-one__title-edit'  /> : <RiPencilFill className='accordion-one__title-edit'  />}
                                                    </button> */}
                                                </div>
                                            </div>
                                            <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                <div style={{ height: "28px", width: "28px" }}>
                                                    <div className={`${classes.check_container}   green_check_container`}>
                                                        <label className={`${classes.check_margintop} checkbox_container`}>
                                                            <input type="checkbox" />
                                                            <span class="checkbox_checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                <input className='accordion-one__title-input' />
                                            </div> */}
                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="4">
                                        <Card.Body className='p-0'>
                                            <div className='row w-100 mx-auto align-items-center mt-1'>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Description of Company Profile</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, companyProfile: !editFormStatus?.companyProfile })}>
                                                                {editFormStatus?.companyProfile ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.companyProfile ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4'>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>List of Departments</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, listOfDepartments: !editFormStatus?.listOfDepartments })}>
                                                                {editFormStatus?.listOfDepartments ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.listOfDepartments ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>Empty</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4'>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>

                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>Travel time to the location from Jakarta</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, travelTime: !editFormStatus?.travelTime })}>
                                                                {editFormStatus?.travelTime ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.travelTime ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                            <div className='row w-100 mx-auto align-items-center mt-4'>
                                                <div className='col-12 col-sm-7 px-3 px-sm-5'>
                                                    <div>
                                                        <div className='d-flex align-items-center gap-3'>
                                                            <p className='accordion-one__collapse--title'>If there is a potential conflict of interest, has it been 3 years?</p>
                                                            <button className='d-flex aling-items-center justify-content-center bg-transparent border-0 outline-0' onClick={() => SetEditFormStatus({ ...editFormStatus, potentialConflict: !editFormStatus?.potentialConflict })}>
                                                                {editFormStatus?.potentialConflict ? <IoClose className='accordion-one__title-edit' /> : <RiPencilFill className='accordion-one__title-edit' />}
                                                            </button>
                                                        </div>
                                                        <div className=''>

                                                            {editFormStatus?.potentialConflict ? <input className='accordion-one__title-input mt-1' /> : <p className='accordion-one__collapse--value'>1234567890</p>}
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-4 col-sm-2 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <div style={{ height: "28px", width: "28px" }}>
                                                        <div className={`${classes.check_container}   green_check_container`}>
                                                            <label className={`${classes.check_margintop} checkbox_container`}>
                                                                <input type="checkbox" />
                                                                <span class="checkbox_checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-8 col-sm-3 d-flex justify-content-center mt-3 mt-sm-0'>
                                                    <input className='accordion-one__title-input' placeholder='Remarks' />
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>



                        </div>
                        <div className='mt-5 px-3'>
                            <p className='accordion-one__title'>Additional Remarks</p>
                            <textarea className={`${classes.textarea}`} />
                        </div>
                        <div className='row mt-5'>
                            <div className='col-6 d-flex justify-content-end'>
                                <button className={`${classes.formButton}`} onClick={handleReject}>Reject</button>
                            </div>
                            <div className='col-6 d-flex justify-content-start'>
                                <button className={`${classes.formButton}`} onClick={handleApprove}>Approve</button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                {/* <Modal.Footer>
                    <button onClick={handleClose}>Close</button>
                </Modal.Footer> */}
            </Modal >
        </>
    )
}

export default ApplicationReview