
/** npm import */

import React from 'react'
import { Navigate, Route, Routes } from "react-router-dom";
import Signin from './pages/Signin';
import Projects from './pages/Projects';
import Dashboard from './pages/Dashboard';
import Task from './pages/Task';
import ThankYou from './pages/ThankYou';

/** local file import */




/** code start */

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="*" element={<Navigate to="/dashboard" />} />
        <Route path='/' element={<Signin />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/projects' element={<Projects />} />
        {/* <Route path='/task' element={<Task />} /> */}
        <Route path='/thank-you' element={<ThankYou />} />

      </Routes>
    </div>
  )
}

export default App