import React, { useContext, useRef, useState } from "react";
import { GlobalContext } from "../context/Context";
import Sidebar from "./Sidebar";
import Header from "./Header";

const Layout = ({ children }) => {
    const { isSidebarOpen } = useContext(GlobalContext);


    return (
        <div>
            <Sidebar />
            <Header />
            <div className={` ${isSidebarOpen ? "width_less" : "width_full"}`}>
                <div className="dash_mainbar">
                    <div className="container container_95 ">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;
