import React, { createContext, useContext, useState } from "react";

// import package
import { Link, NavLink } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";

// import local
import { Images } from "../data/images";
import { GlobalContext } from "../context/Context";
import useWindowResize from "../hooks/useWindowResize";

const navigationLinks = [
    {
        id: 1,
        img: Images.dashboard,
        name: "Dashboard",
        linkTo: "/dashboard",
    },
    {
        id: 2,
        img: Images.customer,
        name: "Customer Feedback",
        linkTo: "/customer-feedback",
    },
    {
        id: 3,
        img: Images.project,
        name: "Projects",
        linkTo: "/projects",
    },
    {
        id: 4,
        img: Images.task,
        name: "Task",
        linkTo: "/task",
    },
    {
        id: 5,
        img: Images.audit,
        name: "Audit Results",
        linkTo: "/audit-results",
    },

];

const popularNavigationLinks = [
    {
        id: 1,
        img: Images.reminders,
        name: "Reminders",
        linkTo: "/reminders",
    },
    {
        id: 2,
        img: Images.plan,
        name: "Plan Audit",
        linkTo: "/plan-audit",
    },
    {
        id: 1,
        img: Images.auditbg,
        name: "Audit",
        linkTo: "/audit",
    },
    {
        id: 1,
        img: Images.postaudit,
        name: "Post Audit",
        linkTo: "/post-audit",
    }
]

const Sidebar = () => {
    const { checkContext, handleSidebar, isSidebarOpen, setIsSidebarOpen } =
        useContext(GlobalContext);

    const windowSize = useWindowResize();
    const { width, height } = windowSize;

    console.log("check", checkContext, isSidebarOpen);

    return (
        <div className="dash_sidebar">
            <div
                className={`dash_sidebar_content h-100 ${isSidebarOpen ? "active" : ""
                    }`}
            >
                <div
                    className={`sidebar_header d-flex align-items-center  ${isSidebarOpen
                        ? "justify-content-between p-3 "
                        : "justify-content-center p-2 "
                        }`}
                >
                    {isSidebarOpen && (
                        <div>
                            <img
                                src={Images.dashlogo}
                                alt="logo"
                                className="img-fluid sidebar_logo"
                            />
                        </div>
                    )}

                    <button
                        className="sidebar_controller_button d-flex align-items-center justify-content-center"
                        onClick={handleSidebar}
                    >
                        <FaArrowRight fill="#fff" />
                    </button>
                </div>
                <div className="sidebar_body d-flex flex-column  pt-4">
                    <ul className="px-0 sidebar_ul top">
                        {navigationLinks.map((item) => (
                            <li className="mb-2">
                                <NavLink
                                    to={item.linkTo}
                                    className="sidebar_navlink text-decoration-none position-relative gap-3 "
                                >
                                    {/* {isSidebarOpen && <div className="sidebar__greenLine"></div>} */}

                                    <div
                                        className={`d-flex align-items-center  sidebar__linkOnly gap-3  ${isSidebarOpen
                                            ? "ms-3 me-3 px-3 justify-content-start"
                                            : "mx-2 justify-content-center"
                                            }`}
                                    >
                                        <img src={item.img} alt={item.name} className="img-fluid" />
                                        {isSidebarOpen && (
                                            <p className="mb-0 sidebar_name">{item.name}</p>
                                        )}
                                    </div>
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                    <div className="sidebar_separate mx-2"></div>
                    <p className="sidebar_bottom_title px-3">Popular</p>
                    <ul className="px-0 sidebar_ul bottom mt-4">
                        {popularNavigationLinks.map((item) => (
                            <li className="mb-2">
                                <NavLink
                                    to={item.linkTo}
                                    className="sidebar_navlink text-decoration-none position-relative gap-3 "
                                >
                                    {/* {isSidebarOpen && <div className="sidebar__greenLine"></div>} */}

                                    <div
                                        className={`d-flex align-items-center  sidebar__linkOnly gap-3  ${isSidebarOpen
                                            ? "ms-3 me-3 px-3 justify-content-start"
                                            : "mx-2 justify-content-center"
                                            }`}
                                    >
                                        <div className="sidebar_iconBg">
                                            <img src={item.img} alt={item.name} className="img-fluid" />
                                        </div>

                                        {isSidebarOpen && (
                                            <p className="mb-0 sidebar_name">{item.name}</p>
                                        )}
                                    </div>
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                    <div className="mt-5 ">
                        <div className="sidebar_profile_imgWrapper mx-auto">
                            <img src={Images.profileIcon} />
                        </div>
                        <div className="sidebar_customerCard mt-4 mx-3">
                            <img src={Images.chat} className="img-fluid" alt="chat" />
                            <div>
                                <p className="title">Customer Service</p>
                                <p className="subtitle">email@qualitasertifikasi</p>
                            </div>
                        </div>

                    </div>
                    <div className=" ">
                        <ul className="px-0 sidebar_ul top  mt-4">
                            <li className="mb-2">
                                <NavLink
                                    to='/setting'
                                    className="sidebar_navlink text-decoration-none position-relative gap-3 "
                                >
                                    {/* {isSidebarOpen && <div className="sidebar__greenLine"></div>} */}

                                    <div
                                        className={`d-flex align-items-center  sidebar__linkOnly gap-3  ${isSidebarOpen
                                            ? "ms-3 me-3 px-3 justify-content-start"
                                            : "mx-2 justify-content-center"
                                            }`}
                                    >
                                        <img src={Images.settings} alt="Settings" className="img-fluid" />
                                        {isSidebarOpen && (
                                            <p className="mb-0 sidebar_name">Settings</p>
                                        )}
                                    </div>
                                </NavLink>
                            </li>
                            <li className="mb-2">
                                <button
                                    className="border-0 outline-0 bg-transparent w-100   sidebar_navlink text-decoration-none position-relative gap-3 "
                                >
                                    <div
                                        className={`d-flex align-items-center  sidebar__linkOnly gap-3  ${isSidebarOpen
                                            ? "ms-3 me-3 px-3 justify-content-start"
                                            : "mx-2 justify-content-center"
                                            }`}
                                    >
                                        <img src={Images.logout} alt="Settings" className="img-fluid" />
                                        {isSidebarOpen && (
                                            <p className="mb-0 sidebar_name">Sign Out</p>
                                        )}
                                    </div>
                                </button>
                            </li>
                        </ul>

                    </div>


                </div>
            </div>
        </div>
    );
};

export default Sidebar;
